import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'
import { FormProvider, useForm } from "react-hook-form"

import AssetsDialog from '../../components/assets/dialog'
import AutoSave from '../../components/autosave'
import { useTitle } from '../../components/browser'
import Errors from '../../components/errors'
import Boolean from '../../components/form/boolean'
import DateInput from '../../components/form/dateinput'
import Fieldset from '../../components/form/fieldset'
import Input from '../../components/form/input'
import Integer from '../../components/form/integer'
import Metafields from '../../components/form/partials/meta-fields'
import Radios from '../../components/form/radios'
import FormSections from '../../components/form/sections'
import Slug from '../../components/form/slug'
import Textarea from '../../components/form/textarea'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import NotificationContext from '../../components/notifications/context'
import SectionEditingStatus from '../../components/record/editing-status'
import RecordPublishButton from '../../components/record/publish-button'
import RecordSchedule from '../../components/record/schedule'
import RecordTimestamps from '../../components/record/timestamps'
import RecordUnpublishButton from '../../components/record/unpublish-button'
import RecordsDialog from '../../components/records/dialog'
import StatusBadge from '../../components/status/badge'
import recipeFragment from '../../fragments/recipe'
import { requireAuthenticatedEditor } from '../../services/authentication'

const GET_RECIPE_QUERY = gql`
  query GetRecipe($id: ID!) {
    recipe(id: $id) {
      ${recipeFragment}
    }
  }
`

const UPDATE_RECIPE_MUTATION = gql`
  mutation UpdateRecipeMutation($id: ID!, $titleDe: String, $titleEn: String, $slugDe: String, $slugEn: String, $teaserDe: String, $teaserEn: String, $coverImage: ID, $categories: [ID!], $author: ID, $publishDate: ISO8601DateTime, $advertisement: Boolean, $metaTitleDe: String, $metaTitleEn: String, $metaDescriptionDe: String, $metaDescriptionEn: String, $keywordsDe: String, $keywordsEn: String, $preperationTime: Int, $portionSize: String, $difficulty: String, $flavor: String, $vgWortId: String, $soyFree: Boolean, $withoutNuts: Boolean, $glutenFree: Boolean ) {
    updateRecipe(input: { id: $id, titleDe: $titleDe, titleEn: $titleEn, slugDe: $slugDe, slugEn: $slugEn, teaserDe: $teaserDe, teaserEn: $teaserEn, coverImage: $coverImage, categories: $categories, author: $author, publishDate: $publishDate, advertisement: $advertisement, metaTitleDe: $metaTitleDe, metaTitleEn: $metaTitleEn, metaDescriptionDe: $metaDescriptionDe, metaDescriptionEn: $metaDescriptionEn, keywordsDe: $keywordsDe, keywordsEn: $keywordsEn, preperationTime: $preperationTime, portionSize: $portionSize, difficulty: $difficulty, flavor: $flavor, vgWortId: $vgWortId, soyFree: $soyFree, withoutNuts: $withoutNuts, glutenFree: $glutenFree }) {
      recipe {
        ${recipeFragment}
      }
    }
  }
`
export default function RecipesForm({ id }) {
  requireAuthenticatedEditor()

  const { addNotification } = useContext(NotificationContext)
  const methods = useForm()
  const { loading, error, data } = useQuery(GET_RECIPE_QUERY,
    {
      variables: { id },
      onCompleted: (data) => methods.reset(data.recipe)
    }
  )
  const [mutate, { loading: mutating }] = useMutation(UPDATE_RECIPE_MUTATION, {
    onError: () => {
      addNotification({ title: 'Error updating recipe', message: 'The recipe could not be updated.', type: 'error' })
    }
  })

  const title = useMemo(() => `${data?.recipe?.titleDe} - Recipe`, [data])
  useTitle(title)

  if (error) {
    console.error(error)
    return <div>There was an error loading the recipe.</div>
  }

  const onSubmit = () => { }

  return (
    <Layout>
      <LayoutCompass
        label="recipe"
        title={title}
        backTo='/recipes'
      />
      <LayoutContent loading={loading}>
        <FormProvider {...methods}>
          <div className="flex justify-between space-x-6">
            <div className="bg-white rounded-lg shadow flex-1 p-6">
              <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col space-y-8 flex-1">
                <Errors errors={methods.formState.errors} />
                <Fieldset>
                  <Input
                    label="Title - German"
                    name="titleDe"
                    register={methods.register}
                  />
                  <Input
                    label="Title- English"
                    name="titleEn"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Slug
                    label="Slug - German"
                    name="slugDe"
                    control={methods.control}
                  />
                  <Slug
                    label="Slug - English"
                    name="slugEn"
                    ccontrol={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <DateInput
                    label="Publish Date"
                    name="publishDate"
                    control={methods.control}
                    datetime
                  />
                </Fieldset>
                <Fieldset>
                  <RecordsDialog
                    label='Categories'
                    value={data?.recipe?.categories}
                    methods={methods}
                    name="categories"
                    multiple
                    emptyLabel="No categories selected."
                    query={`
                      query GetCategories($search: String) {
                        records: allCategories(first: 200, order: ASC, search: $search) {
                          edges {
                            node {
                              __typename
                              id
                              titleDe
                              editingStatus
                            }
                          }
                        }
                      }`
                    }
                  />
                </Fieldset>
                <Fieldset>
                  <RecordsDialog
                    label='Author'
                    value={data?.recipe?.author}
                    methods={methods}
                    name="author.id"
                    emptyLabel="No author selected."
                    query={`
                      query GetAuthors($search: String) {
                        records: allEditors(first: 200, search: $search) {
                          edges {
                            node {
                              __typename
                              id
                              titleDe: displayName
                            }
                          }
                        }
                      }
                    `}
                  />
                </Fieldset>
                <Fieldset>
                  <Textarea
                    label="Teaser - German"
                    name="teaserDe"
                    control={methods.control}
                  />
                  <Textarea
                    label="Teaser - English"
                    name="teaserEn"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <AssetsDialog
                    label='Cover Image'
                    value={data?.recipe?.coverImage}
                    methods={methods}
                    name="coverImage.id"
                  />
                </Fieldset>
                <Fieldset>
                  <FormSections
                    recordId={data?.recipe?.id}
                    sections={data?.recipe?.sections}
                  />
                </Fieldset>
                <Fieldset>
                  <Boolean
                    label="Advertisement"
                    name="advertisement"
                    register={methods.register}
                  />
                </Fieldset>
                <Metafields methods={methods} />
                <Fieldset>
                  <Integer
                    label="Preperation Time"
                    name="preperationTime"
                    control={methods.control}
                  />
                  <Radios
                    label="Portion Size"
                    name="portionSize"
                    register={methods.register}
                    options={[
                      { value: 'small', label: 'Small' },
                      { value: 'medium', label: 'Medium' },
                      { value: 'large', label: 'Large' }
                    ]}
                  />
                  <Radios
                    label="Difficulty"
                    name="difficulty"
                    register={methods.register}
                    options={[
                      { value: 'easy', label: 'Easy' },
                      { value: 'medium', label: 'Medium' },
                      { value: 'difficult', label: 'Difficult' }
                    ]}
                  />
                  <Radios
                    label="Flavor"
                    name="flavor"
                    register={methods.register}
                    options={[
                      { value: 'sweet', label: 'Sweet' },
                      { value: 'savory', label: 'Savory' }
                    ]}
                  />
                </Fieldset>
                <Fieldset>
                  <Boolean
                    label="Gluten Free"
                    name="glutenFree"
                    register={methods.register}
                  />
                  <Boolean
                    label="Soy Free"
                    name="soyFree"
                    register={methods.register}
                  />
                  <Boolean
                    label="Without Nuts"
                    name="withoutNuts"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Input
                    label="VG Wort Id"
                    name="vgWortId"
                    register={methods.register}
                  />
                </Fieldset>
              </form>
            </div>
            <AutoSave
              id={id}
              mutate={mutate}
              control={methods.control}
            />
            <div className="w-3/12 bg-white shadow rounded-lg sticky top-24 self-start space-y-4 p-6 text-gray-500">
              <div className="flex justify-between items-center">
                <div className="font-bold">Status</div>
                <StatusBadge status={data?.recipe?.editingStatus} />
              </div>
              <RecordPublishButton
                record={data?.recipe}
                label="recipe"
                methods={methods}
              />
              <RecordUnpublishButton
                record={data?.recipe}
                label="recipe"
                methods={methods}
              />
              <SectionEditingStatus
                isDirty={methods.formState.isDirty}
                mutating={mutating}
              />
              <RecordSchedule
                record={data?.recipe}
                label="recipe"
                methods={methods}
              />
              <RecordTimestamps
                record={data?.recipe}
              />
            </div>
          </div>
        </FormProvider>
      </LayoutContent>
    </Layout>
  )
}
